.overview-wrapper .stats-panel {
  width: 100%;
  height: 100%;
}

.canvas-container {
  position: relative;
  height: 70%;
}

.stats-panel-settings-delete,
.stats-panel-settings-modify {
  position: absolute;
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 40px;
  background: var(--red-color);
  border-radius: 10px;
  cursor: pointer;
}

.stats-panel-settings-modify {
  background: var(--main-color);
}

.stats-panel-settings-modify:hover {
  background: var(--shade-main-color);
}

.panel-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 200px;
  width: 200px;
  background: var(--lighter-background-color);
  cursor: pointer;
  border-radius: 10px;
}

.panel-item:hover {
  background: var(--extra-light-background-color);
}

.panel-item-image {
  max-height: 130px;
  max-width: 190px;
}

.panel-item-title {
  font-size: 1.6rem;
  text-align: center;
}

.panel-item-hover {
  display: none;
}

.panel-list-item-desc {
  font-size: 1.1rem;
  padding-inline-start: 15px;
}

/*GoalsListItem*/

.panel-goals-wrapper,
.panel-notes-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.panel-notes-header,
.panel-goals-header {
  display: flex;
  align-items: center;
  height: 70px;
  padding-left: 20px;
  font-size: 1.3rem;
}

.panel-notes-list-wrapper,
.panel-goals-list-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  height: 100%;
  overflow-y: auto;
}

.panel-notes-item-wrapper,
.panel-goals-item-wrapper {
  display: flex;
  width: 100%;
  min-height: 70px;
  max-height: 70px;
  margin-bottom: 15px;
  cursor: pointer;
}

.panel-goals-item-color {
  width: 15px;
  height: 100%;
  border-radius: 8px 0 0 8px;
}

.panel-notes-item-wrapper,
.panel-goals-item-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  width: 100%;
  height: 100%;
  background: var(--lighter-background-color);
  border-radius: 0 8px 8px 0;
}

.panel-notes-item-wrapper {
  border-radius: 8px;
}

.panel-goals-item-wrapper:hover .panel-goals-item-main,
.panel-notes-item-wrapper:hover {
  background: var(--extra-light-background-color);
}

.panel-goals-no-items-wrapper,
.panel-notes-no-items-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.panel-goals-no-items,
.panel-notes-no-items {
  margin-bottom: 70px;
  font-size: 1.5rem;
}

.panel-no-game-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.panel-no-game-wrapper button {
  border: none;
  background: var(--main-color);
  font-size: 1.2rem;
  height: 50px;
  width: 250px;
  border-radius: 5px;
  cursor: pointer;
}

.panel-no-game-wrapper button:hover {
  background: var(--shade-main-color);
}

.stats-panel-error-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
}

.stats-panel-error-wrapper h1,
.stats-panel-error-wrapper h3 {
  margin-bottom: 10px;
}

.stats-panel-error-wrapper p {
  color: #bbb;
}

.stats-panel-chart-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
}

.stats-panel-chart-header {
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  padding-left: 20px;
  font-size: 1.3rem;
}

.stats-panel-chart-line-outer {
  padding: 0 15px 15px 15px;
  height: calc(100% - 60px);
  width: 100%;
}

.stats-panel-chart-line {
  background: var(--lighter-background-color);
  border-radius: 6px;
  padding: 10px;
  height: 100%;
  width: 100%;
}

/* Popup */

.panel-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
}

.panel-popup-inner {
  position: relative;
  height: calc(100% - 80px);
  width: calc(100% - 80px);
  background: var(--background-color);
  border-radius: 15px;
  border: solid 1px var(--lighter-background-color);
}

.panel-popup-header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  border-bottom: solid 2px var(--main-color);
}

.panel-popup-cancel {
  display: flex;
  align-items: center;
  position: absolute;
  right: 14px;
  cursor: pointer;
}

.panel-popup-go-back {
  display: flex;
  align-items: center;
  position: absolute;
  left: 17px;
  padding-right: 50px;
  cursor: pointer;
}

.panel-popup-go-back img {
  height: 30px;
}

.panel-popup-item-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);
  grid-gap: 25px;
  justify-content: center;
  margin: 25px 0;
  width: 100%;
}

.panel-popup-overflow {
  height: calc(100% - 60px);
  overflow: auto;
}

@media only screen and (max-width: 970px) {
  .panel-popup {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 10000;
  }

  .panel-popup-inner {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}
