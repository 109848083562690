@media only screen and (max-width: 1500px) {
  .notes-panel-wrapper {
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  .notes-editor-wrapper {
    height: 100%;
  }

  .notes-large-panel-wrapper {
    width: 100%;
  }

  .notes-large-panel-wrapper {
    margin: 0;
  }

  .notes-small-panel-wrapper {
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin: 0;
    background: var(--background-color);
  }
  .notes-small-box {
    max-width: 500px;
  }

  .notes-desktop-small-panel {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .notes-list-background {
    flex-direction: column;
  }

  .notes-max-amount-items-wrapper {
    width: 100%;
    max-height: 35%;
    min-height: 35%;
  }

  .notes-max-amount-items-wrapper .misc-max-amount-wrapper {
    flex-direction: row-reverse;
    text-align: start;
    justify-content: center;
  }

  .notes-max-amount-items-wrapper .misc-max-amount-txt {
    margin-top: 0;
  }

  .notes-list-left {
    width: 100%;
    height: 100%;
  }

  .notes-list-right {
    width: 100%;
    height: 65%;
  }

  .notes-large-panel-divider {
    max-height: 2px;
    min-height: 2px;
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  #note-editor {
    max-width: 200px;
  }
}

@media only screen and (max-width: 970px) {
  .notes-list-item .notes-list-item-right-wrapper {
    display: flex;
  }

  .overview-wrapper {
    overflow-y: auto;
  }


  .overview-inner-wrapper > div {
    height: 300px;
  }

  .notes-wrapper {
    padding: 0;
    border-radius: 0;
  }
  .notes-large-panel-wrapper {
    max-width: 100vw;
    width: 100vw;
  }

  .note-editor-external-wrapper {
    height: 100%;
    overflow-x: auto;
    width: 100vw;
    align-self: center;
  }

  .notes-editor-wrapper {
    border: none;
  }

  #notes-editor {
    display: flex;
    justify-content: center;
    min-width: 750px;
    max-width: 750px;
    background: none;
    margin: 0;
  }

  #notes-editor .ql-editor {
    width: 600px;
    height: 100%;
    background: var(--background-color);
  }

  .notes-tabs-wrapper {
    border-bottom: solid 2px #555;
  }

  .notes-divider,
  .notes-large-panel-divider {
    background: #555;
  }

  .notes-list-background {
    height: 100%;
  }

  .notes-max-amount-items-wrapper {
    height: 45%;
    width: 100%;
  }

  .notes-list-left {
    width: 100%;
    height: 100%;
  }

  .notes-tabs-wrapper {
    border-radius: 0;
  }

  .notes-toolbar-wrapper {
    position: relative;
    align-items: left;
    overflow: visible;
    height: 42px;
    width: 100vw;
    background: var(--background-color);
  }

  .ql-toolbar.ql-snow {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    min-width: 100vw;
    height: 50px;
    background: none;
    overflow-x: auto;
    padding-bottom: 250px;
    border: none;
  }

  .ql-toolbar.ql-snow::-webkit-scrollbar {
    display: none;
  }

  .ql-toolbar.ql-snow {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .ql-toolbar.ql-snow > * {
    display: flex;
    flex-wrap: nowrap;
  }
}

@media only screen and (max-width: 740px) {
  .note-editor-external-wrapper {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 640px) {
  .snotes-editor {
    justify-content: flex-start;
  }
}
