.cookie-full-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100vw;
  min-width: 300px;
  overflow-x: auto;
}
.cookie-wrapper {
  display: flex;
  position: fixed;
  background: var(--main-color);
  height: 110px;
  width: 100%;
  bottom: 0;
  z-index: 10000000 !important;
}

.cookie-inner-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cookie-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 30px;
}

.cookie-large-text {
  font-size: 1.11rem;
}

.cookie-small-text {
  color: #eee;
}

.cookie-right-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 100px;
}

.cookie-button-wrapper {
  margin-right: 60px;
}

.cookie-button-wrapper button {
  font-size: 1.1rem;
  color: #eee;
  background: #156a87;
  border: none;
  padding: 14px 70px;
  cursor: pointer;
}

.cookie-button-wrapper button:hover {
  opacity: 0.9;
}

.cookie-exit {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.cookie-exit img {
  height: 35px;
  width: 35px;
}

@media only screen and (max-width: 1210px) {
  .cookie-wrapper {
    height: 200px;
  }

  .cookie-text-wrapper {
    margin-left: 40px;
  }

  .cookie-inner-wrapper {
    flex-direction: column;
    justify-content: space-evenly;
  }

  .cookie-right-wrapper {
    align-self: flex-end;
    margin-right: 40px;
  }
}

@media only screen and (max-width: 450px) {
  .cookie-wrapper {
    height: 250px;
  }
}

@media only screen and (max-width: 315px) {
  .cookie-wrapper {
    height: 550px;
  }

  .cookie-text-wrapper {
    margin: 0 20px;
  }

  .cookie-right-wrapper {
    align-self: center;
    margin: 0;
  }

  .cookie-button-wrapper button {
    width: 100vw;
    padding: 20px 0px;
  }

  .cookie-inner-wrapper {
    flex-direction: column;
    justify-content: space-evenly;
  }
}
