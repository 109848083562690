@media only screen and (max-width: 1160px) {
  .home-overview-wrapper {
    grid-template-columns: 400px 400px;
    grid-template-rows: 400px 400px 400px;
    grid-row-gap: 215px;
    padding: 5px 0;
  }

  .home-overview-image {
    width: 400px;
  }

  .home-overview-large-text {
    font-size: 3.5rem;
  }

  .home-overview-small-text {
    font-size: 1.6rem;
  }

  .home-overview-text {
    width: 400px;
  }
}

@media only screen and (max-width: 950px) {
  .home-overview-wrapper {
    display: grid;
    justify-items: center;
    grid-column-gap: 0 !important;
    grid-row-gap: 25px !important;
    grid-template-columns: minmax(100%, 400px);
    grid-template-rows: auto;
    grid-row-gap: 170px;
    grid-template-areas:
      "i1"
      "t1"
      "i2"
      "t2"
      "i3"
      "t3";
  }

  .home-overview-image {
    width: 100%;
    max-width: 400px;
  }

  .home-overview-large-text {
    font-size: 2.8rem;
    width: 80vw;
  }

  .home-overview-small-text {
    font-size: 1.3rem;
  }

  .home-overview-text {
    width: 100%;
    max-width: 400px;
  }
}

@media only screen and (max-width: 770px) {
  .home-header-wrapper {
    padding: 0 10px !important;
    height: 70px !important;
  }

  .home-start-left {
    text-align: center;
  }

  .home-start-inner-wrapper {
    align-items: center;
  }

  .home-action-wrapper {
    flex-direction: column;
    justify-content: space-evenly;
  }

  .home-action-button {
    width: 90%;
  }

  .home-action-divider {
    width: 80%;
    height: 0;
  }
}

@media only screen and (max-width: 630px) {
  .home-start-left-big {
    font-size: 25vw;
  }

  .home-start-left-small {
    font-size: 6vw;
  }
}

@media only screen and (max-width: 500px) {
  .home-overview-large-text {
    font-size: 2.3rem;
    width: 80vw;
  }

  .home-overview-small-text {
    font-size: 1.4rem;
  }
}
