* {
  font-family: "Exo 2", sans-serif;
  color: white;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

body {
  background: var(--lighter-background-color);
}

.main-body-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.main-body-inner-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: 100px 1fr;
  grid-template-areas:
    "si he"
    "si ma";
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.main-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

/*Alert*/

.main-alert {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  width: 100%;
  height: 45px;
  background: var(--red-color);
  transition: height 0.6s;
  overflow: hidden;
}

.main-alert-text-desktop {
  display: block;
}

.main-alert-text-mobile {
  display: none;
}

.main-alert img {
  cursor: pointer;
}

.main-alert-text {
  display: flex;
  align-items: center;
}

.main-alert-sign {
  margin: 0 4px;
  font-size: 1rem;
  padding: 3px 6px;
  border: 1px solid #fff;
  background: none;
  border-radius: 5px;
  cursor: pointer;
}

.main-alert-sign:hover {
  background: #fff;
  color: var(--red-color);
}

/*Info Bubble*/

.main-info-wrapper {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 1000;
}

.main-info-wrapper a {
  color: var(--main-color);
  text-decoration: underline;
  cursor: default;
}

.main-info-bubble {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  font-size: 2rem;
  font-weight: 600;
  background: var(--main-color);
  border-radius: 50%;
  cursor: pointer;
}

.main-info-bubble img {
  width: 24px;
  height: 24px;
  margin-top: 1px;
  margin-left: 1px;
}

.main-info-box-wrapper {
  display: block;
  position: relative;
  bottom: 60px;
  width: 300px;
  height: 0;
  opacity: 0;
  background: var(--background-color);
  border: 2px solid var(--main-color);
  border-radius: 8px;
  z-index: 2;
  overflow: hidden;
  transition: opacity 0.4s, bottom 0.4s;
}

.main-info-box-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px;
  height: 50px;
  width: 100%;
  border-bottom: 2px solid var(--main-color);
}

.main-info-box-header img {
  height: 25px;
  cursor: pointer;
}

.main-info-item {
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 1.1rem;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #555;
  cursor: default;
}

.main-info-item:hover {
  background: var(--lighter-background-color);
}

.main-info-text-wrapper {
  position: absolute;
  top: 51px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: calc(100% - 52px);
  background: var(--background-color);
  cursor: pointer;
}
.main-info-text {
  padding: 12px;
  font-size: 1.1rem;
}
.main-info-bottom-close {
  height: 20px;
  color: #aaa;
  align-self: center;
}

/* HEADER */

.main-header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-area: he;
  width: 100%;
  align-items: center;
  background: var(--background-color);
  z-index: 5;
}

.main-header-title {
  margin-left: 30px;
  font-size: 60px;
  font-weight: 200;
  flex-grow: 1;
  text-transform: capitalize;
}

.main-header-right {
  display: flex;
}

.main-settings-icon-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--main-color);
  height: 100px;
  width: 100px;
}

.main-settings-icon {
  width: 50px;
  height: 50px;
}

.main-header-select-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.main-header-select {
  background: none;
  border: none;
  font-size: 1.1rem;
  max-width: 200px;

  background: linear-gradient(45deg, transparent 50%, #eee 50%),
    linear-gradient(135deg, #eee 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc), var(--main-color);
  background-position: calc(100% - 20px) calc(0.85em + 0px),
    calc(100% - 15px) calc(0.85em + 0px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1em, 1px calc(100% - 2.5em);
  background-repeat: no-repeat;

  /*border: 1px #eee solid;*/
  border-radius: 5px;
  line-height: 1.1em;
  padding: 0.4em 3.5em 0.4em 0.8em;
  margin-right: 15px;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  cursor: pointer;
}

.main-header-settings {
  cursor: pointer;
}

.main-menu-icon {
  display: none;
}

.main-header-update-mobile {
  display: none;
}

.main-header-update-desktop {
  width: 120px;
  height: 100px;
}

.main-header-update-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.main-header-timer-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding-top: 15px;
}

.main-header-timer-bar {
  width: 70%;
  height: 15px;
  background: #fff;
}

.main-header-timer-bar > div {
  height: 100%;
  background: var(--green-color);
}

.main-header-update-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.main-header-update-spinner img {
  width: 50px;
  height: 50px;
}

#update-btn-spin {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* MAIN */

.main-wrapper {
  display: flex;
  grid-area: ma;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}

/* No profile */

.main-np-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.main-np-inner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 60%;
  max-width: 800px;
  max-height: 450px;
  border-radius: 20px;
  background: var(--background-color);
}

.main-np-inner-wrapper form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 60%;
  max-width: 800px;
  max-height: 450px;
}

.main-np-inner-wrapper h3 {
  font-size: 1.6rem;
}

.main-np-inner-wrapper p {
  font-size: 1.2rem;
}

.main-np-inner-wrapper button {
  border: none;
  background: var(--main-color);
  font-size: 1.2rem;
  height: 50px;
  width: 250px;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 30px;
}

.main-np-inner-wrapper button:hover {
  background: var(--shade-main-color);
}

.main-np-inner-wrapper input {
  font-size: 1.2rem;
  height: 50px;
  width: 80%;
  background: var(--extra-light-background-color);
  border: none;
  padding-left: 10px;
  border-radius: 6px;
}
