.no-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: var(--lighter-background-color);
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.no-page-inner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
}

.no-page-message-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1000px;
}

.no-page-divider {
  max-height: 200px;
  height: 50%;
  border: 3px solid #fff;
}

.no-page-not-found {
  font-size: 10rem;
  padding: 20px;
}

.no-page-go-home {
  font-size: 2rem;
  cursor: pointer;
  padding: 15px 25px;
  border-radius: 5px;
  background: var(--main-color);
  height: 80px;
  width: 220px;
  border: none;
}

.no-page-go-home:hover {
  background: var(--shade-main-color);
}

.no-page-contact-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  bottom: 20px;
}

.no-page-contact-wrapper p {
  font-size: 1.2rem;
}

.no-page-contact-wrapper .no-page-divider {
  height: 40px;
  border: 1px solid #fff;
}

.no-page-contact-wrapper h3 {
  margin: 10px;
}

.no-page-contact-link-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 320px;
}

.no-page-contact-link-wrapper > *:not(.no-page-divider) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-page-contact-link-wrapper a {
  display: flex;
}

.no-page-contact-link-wrapper a img {
  margin: 5px;
  height: 30px;
}

.no-page-contact-link-wrapper a:hover p {
  text-decoration: underline;
}
