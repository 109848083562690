.navbar {
  display: none;
}

.sidebar-mobile,
.sidebar-exit,
.sidebar-header-show-sidebar {
  display: none;
}

.sidebar-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 300px;
  background: var(--background-color);
}

.sidebar-desktop {
  display: flex;
  grid-area: si;
}

.sidebar-content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.sidebar-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 100px;
  background: var(--main-color);
  cursor: pointer;
}

.sidebar-top img {
  height: 35px;
  width: 35px;
}

.sidebar-top p {
  font-size: 1.3rem;
  margin: 0 10px;
}

.sidebar-primary-items-wrapper {
  cursor: default;
}

.sidebar-section {
  margin-top: 40px;
}

.sidebar-section-header {
  font-variant-caps: small-caps;
  color: #5f5f5f;
  font-size: 15px;
  padding-left: 40px;
}

.sidebar-section-secondary-wrapper {
  flex: 0 1 content;
  height: 60%;
}

.sidebar-section-primary-wrapper {
  height: 40%;
}

.sidebar-section-secondary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  cursor: default;
}

.sidebar-section-secondary-content {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 23px;
  padding-left: 40px;
  list-style-type: none;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.sidebar-section-secondary-content p {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar-section-delete-icon {
  display: none;
  position: absolute;
  right: 15px;
  z-index: 100;
  cursor: pointer;
}

.sidebar-section-secondary:hover .sidebar-section-delete-icon {
  display: block;
}

.sidebar-section-text {
  font-size: 23px;
  font-family: "Work Sans Light", sans-serif;
  padding: 12.5px 0px;
  padding-left: 40px;
  list-style-type: none;
}

.sidebar-section-icon {
  position: relative;
  margin-top: 9.375px;
  margin-right: 10px;
  margin-left: 40px;
  float: left;
  width: 30px;
  height: 30px;
  z-index: 10;
}

.sidebar-active {
  position: relative;
  background: var(--lighter-background-color);
  border-left: solid 3px var(--main-color);
}

.sidebar-section-secondary:hover,
.sidebar-section-text:hover {
  background: var(--lighter-background-color);
}

.sidebar-add-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  width: 100%;
}

.sidebar-add-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: var(--extra-light-background-color);
  cursor: pointer;
}

.sidebar-add-button:hover {
  background: #555;
}

.sidebar-verify-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin: 15px;
  border-radius: 5px;
}

.sidebar-verify-input {
  background: var(--extra-light-background-color);
  height: 32px;
  font-size: 1.1rem;
  padding-left: 8px;
  border-radius: 5px;
  border: none;
  color: #fff;
  width: 80%;
}

.sidebar-verify-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--extra-light-background-color);
  cursor: pointer;
  border: none;
  width: 32px;
}

.sidebar-verify-button:hover {
  background: #555;
}

.sidebar-verify-button img {
  width: 15px;
  height: 15px;
}

.sidebar-logout-wrapper {
  height: 50px;
  cursor: pointer;
}

.sidebar-logout-wrapper * {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  height: 100%;
  margin: 0 1px;
  background: var(--main-color);
}

.sidebar-logout-wrapper *:hover {
  opacity: 0.9;
}
