.legal-wrapper * {
  margin: 3px 0;
  color: #eee;
}

.legal-wrapper {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 10px 0;
  overflow-x: hidden;
}

.legal-wrapper p,
.legal-wrapper li,
.legal-p {
  color: #ccc;
}

.legal-inner-wrapper {
  width: 60vw;
  max-width: 700px;
}
