@media only screen and (max-width: 970px) {
  .settings-inner-wrapper {
    width: 100vw;
  }
}

@media only screen and (max-width: 780px) {
  .settings-wrapper {
    align-items: flex-start;
  }

  .settings-header {
    position: fixed;
    flex: 0 0 65px;
  }

  .settings-header h3 {
    font-size: 3rem;
  }

  .settings-header-empty,
  .settings-header-go-back {
    height: 65px;
    padding: 15px 15px 23px 15px;
    width: 65px;
  }

  .settings-prop-header * {
    height: 65px;
  }

  .settings-text-header {
    padding: 20px 0;
    text-align: center;
  }

  .settings-inner-wrapper {
    flex-direction: column-reverse;
    min-height: calc(100vh - 135px);
    max-height: calc(100vh - 135px);
    min-height: calc(calc(var(--vh, 1vh) * 100) - 135px);
    max-height: calc(calc(var(--vh, 1vh) * 100) - 135px);
  }

  .settings-inner-right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .settings-inner-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw !important;
    height: 70px !important;
    background: var(--background-color);
    border: none;
    position: fixed;
    bottom: 0;
  }

  .settings-inner-left-item {
    height: 70px !important;
    border: none;
    border-top: 2px solid var(--main-color);
  }

  .settings-account-wrapper,
  .settings-all-games-inner-wrapper {
    height: calc(100% - 70px);
    padding: 0 10%;
    width: 100%;
  }

  .settings-all-games-wrapper {
    height: 100%;
    width: 100%;
  }

  .settings-game-wrapper {
    margin-bottom: 10px;
  }

  .profile-settings-wrapper {
    max-height: calc(100vh - 215px);
    max-height: calc(calc(var(--vh, 1vh) * 100) - 215px);
  }

  .profile-settings-item {
    margin: 0;
    margin-bottom: 30px;
  }

  .settings-field-value {
    width: 100%;
  }

  .settings-game-field {
    width: calc(100% - 89px);
  }
}

@media only screen and (max-width: 600px) {
  .settings-inner-left {
    min-width: 65px;
    width: 65px;
  }

  .settings-field-value {
    overflow: hidden;
  }
}

@media only screen and (max-width: 340px) {
  .settings-header-empty {
    height: 0px;
    width: 0px;
  }
}

/*Better go back size*/
/*field overflow*/
