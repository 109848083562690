.admin-login-form-wrapper {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.admin-login-form-wrapper * {
  color: #000;
}

.admin-login-form-wrapper button {
  margin-top: 10px;
}

.admin-form-wrapper button,
.admin-form-wrapper input {
  color: #000;
}

.admin-content-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.admin-content-wrapper > div {
  width: 50%;
}

.admin-user-info-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
}

.admin-basic-user-info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 150px;
  font-size: 1.2rem;
  margin-bottom: 10px;
  background: var(--background-color);
}

.admin-changelog-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--background-color);
}

.admin-changelog-inner-wrapper {
  width: 100%;
  max-height: 500px;
  height: 100%;
  overflow-y: auto;
}

.admin-changelog-inner-wrapper > div {
  height: 90px;
  width: 100%;
  font-size: 1.1rem;
}

.admin-changelog-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  font-size: 1.2rem;
  border-bottom: solid 3px var(--extra-light-background-color);
}

.admin-changelog-value {
  color: var(--green-color);
}

.admin-changelog-date {
  color: var(--red-color);
}

.admin-create-account-wrapper {
  display: flex;
  flex-direction: column;
  width: 200px;
}
