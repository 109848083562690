.footer-wrapper {
  position: relative;
  display: grid;
  grid-template-rows: 200px 60px;
  grid-template-columns: auto;
  justify-items: center;
  width: 100%;
  padding: 0 20px;
  background: var(--background-color);
}

.footer-top {
  display: grid;
  grid-template-columns: 1fr 1fr 3fr;
  grid-gap: 40px;
  justify-items: flex-start;
  width: 100%;
  max-width: 1000px;
  margin-top: 30px;
}

.footer-subscribe > * {
  margin: 5px 5px;
}

.footer-subscribe h3 {
  font-size: 2rem;
}

.footer-subscribe-form {
  display: flex;
}

.footer-subscribe-form input {
  border: none;
  border-radius: 0;
  background: #333;
  height: 50px;
  width: 280px;
  font-size: 1.4rem;
  padding-left: 5px;
}

.footer-subscribe-form button {
  border: none;
  border-radius: 0;
  height: 50px;
  width: 100px;
  cursor: pointer;
  background: var(--main-color);
  font-size: 1.2rem;
}

.footer-subscribe-form button:hover {
  background: var(--shade-main-color);
}

.footer-subscribe-under {
  display: flex;
  color: #888;
}

.footer-subscribe-under * {
  color: #888;
  font-size: 0.8rem;
}

.footer-subscribe-under a {
  margin: 0 3px;
  text-decoration: underline;
}

.footer-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-bottom * {
  margin: 0 5px;
  font-size: 0.9rem;
  color: #aaa;
}

@media only screen and (max-width: 650px) {
  .footer-wrapper {
    height: 400px;
    grid-template-rows: 340px 60px;
  }
  .footer-top {
    justify-items: start;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    margin-top: 10px;
    padding-bottom: 40px;
    height: 340px;
    grid-gap: 20px;
  }
  .footer-bottom {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 430px) {
  .footer-subscribe-form input {
    width: 200px;
  }
}
