.home-body {
  position: relative;
  min-height: 130vh;
  background: #222;
  width: 100%;
}

/* Header */

.home-header-wrapper {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  padding: 0 70px;
  height: 140px;
  width: 100%;
  background: var(--background-color);
  transition: height 0.2s, padding 0.2s, background 0.2s;
  z-index: 10;
}

.home-header-left {
  display: flex;
  align-items: center;
}

.home-header-left p {
  font-size: 1.3rem;
}

.home-header-left img {
  width: 35px;
  height: 35px;
  margin-right: 8px;
}

.home-header-right {
  display: flex;
}

.home-header-right button {
  padding: 12px 25px;
  font-size: 1.1rem;
  cursor: pointer;
}

.home-header-right button:hover {
  text-decoration: underline;
}

.home-header-text-button {
  border: none;
  background: none;
}

.home-header-filled-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: var(--main-color);
  border: none;
  width: 150px;
  height: 46px;
}

/*Start*/

.home-start-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.home-start-inner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  padding: 0 20px;
  width: 100%;
  height: 100%;
}

.home-start-wrapper video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-start-left {
  padding: 40px;
}

.home-start-left-big {
  font-size: 7rem;
  font-weight: 600;
}

.home-start-left-small {
  font-size: 2rem;
  font-weight: 600;
  width: 14.7em;
  margin-top: 10px;
  margin-left: 8px;
  /*border-right: 2px solid rgba(255, 255, 255, 0.75);*/
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
}

.home-start-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
}

.home-start-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: var(--main-color);
  /* background: red; */
  height: 80px;
  width: 320px;
  font-size: 1.4rem;
  cursor: pointer;
  z-index: 3;
  transition: background 0.1s, color 0.1s;
}

.home-start-button div {
  font-size: 1.4rem;
}

.home-start-right div {
  font-size: 1.2rem;
}

@keyframes jump {
  0% {
    bottom: 20px;
  }
  50% {
    bottom: 40px;
  }
  100% {
    bottom: 20px;
  }
}

.home-start-go-down {
  position: absolute;
  bottom: 20px;
  width: 40px;
  height: 40px;
  margin: -20px 0 0 -20px;
  -webkit-transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 3px #fff solid;
  border-bottom: 3px #fff solid;
  cursor: pointer;
  align-self: center;
  animation: jump 3s infinite;
}

/*Content*/

.home-content {
  display: flex;
  flex-direction: column;
}

/*Bottom*/

.home-bottom-wrapper {
  position: absolute;
  top: 100vh;
  width: 100%;
  background: var(--lighter-background-color);
  overflow-x: hidden;
  z-index: 5;
}

.home-bottom-padding {
  height: 80px;
  background: var(--lighter-background-color);
  width: 100%;
}

.home-overview-wrapper {
  display: grid;
  grid-template-columns: 500px 500px;
  grid-template-rows: 500px 500px 500px;
  grid-row-gap: 100px;
  grid-column-gap: 120px;
  grid-template-areas:
    "i1 t1"
    "t2 i2"
    "i3 t3";
  justify-content: center;
  align-content: center;
  height: 2100px;
  width: 100%;
  position: relative;
  box-shadow: 0px 0px 36px 13px rgba(20, 20, 20, 0.75);
  background: var(--lighter-background-color);
}

.home-overview-image {
  position: relative;
  width: 500px;
  transition: all 0.5s;
}

.home-overview-text {
  display: flex;
  flex-direction: column;
}

.home-overview-large-text {
  position: relative;
  font-size: 4.2rem;
  font-weight: 600;
  width: 500px;
  transition: all 0.5s;
}

.home-overview-small-text {
  padding-top: 20px;
  font-size: 1.8rem;
  font-weight: 300;
  color: #bbbbcb;
}

.home-action-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
  background: var(--background-color);
  z-index: 15;
}

.home-action-wrapper > * {
  margin: 0 30px;
}

.home-action-button {
  position: relative;
  right: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: var(--main-color);
  height: 90px;
  width: 400px;
  font-size: 1.4rem;
  cursor: pointer;
  z-index: 3;
  border: none;
  opacity: 0;
  transition: background 0.1s, color 0.1s, opacity 0.5s, right 0.5s;
}

.home-action-button:hover,
.home-start-button:hover {
  background: var(--shade-main-color);
  color: #ddd;
}

.home-action-divider {
  border: 1px solid #fff;
  /*width: 30px;*/
  height: 80%;
}

.home-action-text {
  position: relative;
  left: 50%;
  opacity: 0;
  font-size: 1.8rem;
  transition: opacity 0.5s, left 0.5s;
}

/* Animation */

/*.anim-typewriter {*/
/*animation: typewriter 2s steps(40) 1s 1 normal both,*/
/*blinkTextCursor 800ms steps(20) infinite normal;*/
/*}*/
/*@keyframes typewriter {*/
/*from {*/
/*width: 0;*/
/*}*/
/*to {*/
/*width: 14.7em;*/
/*}*/
/*}*/
/*@keyframes blinkTextCursor {*/
/*from {*/
/*border-right-color: rgba(255, 255, 255, 0.75);*/
/*}*/
/*to {*/
/*border-right-color: transparent;*/
/*}*/
/*}*/
