@media only screen and (max-width: 970px) {
  .sidebar-mobile {
    position: fixed;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    z-index: 10000;
  }

  .sidebar-wrapper {
    flex: 0 0 100vw;
  }

  .sidebar-exit {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    width: 70px;
    height: 70px;
    justify-content: flex-end;
    align-content: flex-start;
    padding: 16px;
    cursor: pointer;
  }

  .sidebar-exit img {
    width: 25px;
    height: 25px;
  }

  .sidebar-mobile {
    display: flex;
  }

  .sidebar-desktop {
    display: none;
  }

  .sidebar-logout-wrapper {
    display: flex;
  }

  /*Navbar*/

  .navbar {
    display: block;
    position: fixed;
    bottom: 0;
    background: var(--background-color);
    z-index: 100;
    width: 100vw;
  }

  .navbar .sidebar-primary-items-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-top: solid 2px var(--main-color);
    height: 50px;
    overflow-x: auto;
  }

  .navbar .sidebar-primary-items-wrapper > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
  }

  .navbar .sidebar-primary-items-wrapper > div:hover {
    background: #202023;
  }

  .navbar .sidebar-section-text {
    display: none;
  }

  .navbar .sidebar-section-icon {
    height: 30px;
    width: 30px;
    margin: 0;
    z-index: 1;
  }

  .navbar .sidebar-active {
    border: none;
  }
}
