.stats-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.stats-inner-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-gap: 40px;
  justify-content: center;
  width: 100%;
  max-width: 1060px;
  margin-top: 20px;
}

.stats-panel {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 300px;
  background: var(--background-color);
  border-radius: 10px;
  z-index: 1;
}

.stats-maximum-amount-panel {
  justify-content: center;
  align-items: center;
}

.stats-profile-panel-upper {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 160px;
}

.stats-profile-panel-upper-background {
  display: flex;
  justify-content: center;
  border-radius: 10px 10px 0 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.stats-profile-panel-upper-background img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.stats-profile-panel-profile-picture {
  position: absolute;
  top: 100px;
  display: flex;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  background: #000;
  border: solid 8px var(--background-color);
}

.stats-profile-panel-profile-picture img {
  position: relative;
}

.stats-profile-panel-lower {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 35px;
}

.stats-profile-panel-lower-name {
  font-size: 1.5rem;
  margin-top: 10px;
}

.stats-profile-panel-lower-progress-wrapper {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 15px;
}

.stats-profile-panel-lower-bar {
  display: flex;
  height: 20px;
  width: 70%;
  background: #eee;
}

.stats-profile-panel-lower-bar-inner {
  height: 100%;
  width: 0;
  background: #06d6a0;
}

.stats-profile-panel-lower-level-wrapper {
  display: flex;
}

.stats-profile-panel-lower-level-wrapper div {
  margin: 0 2.5px;
}

.stats-panel-settings-icon-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 50px;
  height: 50px;
  background: var(--main-color);
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.05s ease-in;
  cursor: pointer;
  z-index: 10;
}

.stats-panel-settings-icon {
  width: 25px;
  height: 25px;
}

.stats-panel:hover .stats-panel-settings-icon-wrapper {
  opacity: 1;
}

.stats-panel-settings-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: var(--background-color);
}

.stats-panel-settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 9;
  padding-top: 35px;
}

.stats-panel-settings select {
  margin: 15px 0;
  height: 35px;
  width: 80%;
  border: none;
  background: #333;
}

.stats-panel-settings-delete {
  position: absolute;
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 40px;
  background: #ef476f;
  border-radius: 10px;
  cursor: pointer;
}

.stats-panel-settings-delete:hover {
  background: var(--shade-red-color);
}

.stats-new-panel {
  justify-content: center;
  align-items: center;
  background: none;
  border: dashed 3px #666;
  transition: border 0.2s;
  cursor: pointer;
}

.stats-new-panel:hover {
  border: dashed 3px #999;
}

.stats-new-panel-text {
  font-size: 1.5rem;
  color: #666;
  transition: color 0.2s;
}

.stats-new-panel:hover .stats-new-panel-text {
  color: #999;
}

.stats-no-game-panel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stats-no-game-panel button {
  border: none;
  background: var(--main-color);
  font-size: 1.2rem;
  height: 50px;
  width: 250px;
  border-radius: 5px;
  cursor: pointer;
}
