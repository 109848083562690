/* Loader */

.loader-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.loader-wrapper * {
  font-size: 1.1rem;
}

.loader-text {
  text-align: center;
}

.loader-button {
  border: none;
  background: var(--main-color);
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.loader {
  width: 60px;
  height: 60px;
  background: transparent;
  border: 6px solid transparent;
  border-top-color: var(--main-color);
  border-left-color: var(--main-color);
  border-radius: 50%;
  animation: loader 0.75s infinite ease forwards;
}

@keyframes loader {
  100% {
    transform: rotate(360deg);
  }
}

.small-loader {
  width: 20px;
  height: 20px;
  border-width: 3px;
}

/* Progress */

.progress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  position: absolute;
}
.progress span {
  transition: all 500ms ease;
  background: #eee;
  height: 8px;
  width: 8px;
  display: inline-block;
  border-radius: 10px;
  animation: wave 1s ease infinite;
}

.progress span:nth-child(1) {
  animation-delay: 0;
}
.progress span:nth-child(2) {
  animation-delay: 100ms;
}
.progress span:nth-child(3) {
  animation-delay: 200ms;
}

@keyframes wave {
  0%,
  40%,
  100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(0, -10px);
  }
}

.misc-max-amount-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.misc-max-amount-txt {
  font-size: 1.05rem;
  margin-top: 10px;
}

.misc-max-amount-sign-up {
  font-size: 1.2rem;
  background: var(--main-color);
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 15px;
  height: 45px;
  cursor: pointer;
}

.misc-max-amount-sign-up:hover {
  background: var(--shade-main-color);
}

.misc-logout-wrapper {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
  bottom: 0;
  width: 100%;
  flex: 0 0 50px;
}

.faq-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.faq-wrapper h3 {
  text-align: center;
}

.faq-wrapper a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  max-width: 300px;
  width: 100%;
  background: var(--background-color);
  border-radius: 12px;
  margin-top: 20px;
}

.faq-wrapper a:hover {
  opacity: 0.8;
}

.faq-wrapper a img {
  width: 45px;
}

.faq-wrapper a p {
  font-size: 1.6rem;
  margin-left: 8px;
}
