.goals-wrapper {
  position: relative;
  display: block;
  width: 100%;
  height: calc(100% - 80px);
  background: var(--background-color);
  margin: 40px;
  border-radius: 10px;
}

.goals-search {
  display: flex;
  align-items: center;
  position: relative;
  height: 50px;
  width: 100%;
  border-bottom: 2px solid #555;
}

.goals-search input {
  height: 100%;
  width: 100%;
  background: none;
  border: none;
  font-size: 1.3rem;
  padding-left: 10px;
}

.goals-search-icon {
  position: absolute;
  right: 10px;
}

.goals-search-icon img {
  width: 23px;
  height: 23px;
  filter: invert(62%) sepia(58%) saturate(0%) hue-rotate(212deg) brightness(91%)
    contrast(95%);
  transition: filter 0.2s;
}

.goals-search-tooltip {
  display: none;
  flex-direction: column;
  position: absolute;
  right: 5px;
  top: 40px;
  width: 350px;
  background: var(--background-color);
  border: solid 2px var(--main-color);
  padding: 20px;
  border-radius: 10px;
  z-index: 100;
}

.goals-search-icon:hover .goals-search-tooltip {
  display: flex;
}

.goals-search-tooltip > p {
  margin: 5px 0;
}

.goals-add-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.goals-add-exit-wrapper {
  position: relative;
  height: 80%;
  width: 80%;
}

.goals-add-inner-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  background: var(--background-color);
  border-radius: 10px;
  overflow: auto;
}

.goals-add-default-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 70%;
  min-width: 510px;
  max-width: 550px;
  min-width: 300px;
}

.goals-add-default-wrapper input[type="text"] {
  height: 40px;
  border: none;
  border-radius: 5px;
  width: 100%;
  background: var(--extra-light-background-color);
  margin-top: 16px;
  padding-left: 7px;
}

.goals-add-default-wrapper button {
  height: 40px;
  border: none;
  border-radius: 5px;
  width: 100%;
  background: var(--extra-light-background-color);
  margin: 8px 0;
  padding-left: 7px;
  cursor: pointer;
}

.goals-add-default-wrapper button:hover {
  background: #555;
}

.goals-add-default-color {
  display: flex;
  width: 100%;
  height: 35px;
  margin: 16px 0 8px 0;
}

.goals-add-default-color * {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
}

.goals-add-default-color-picker input {
  position: absolute;
  opacity: 0;
  border-radius: 0 0 5px 5px;
  width: 100%;
  cursor: pointer;
}

.goals-add-default-color > div:nth-child(1) {
  background: var(--extra-light-background-color);
  border-radius: 5px 0 0 5px;
}

.goals-add-default-color div:nth-child(2) {
  border-radius: 0 5px 5px 0;
}

.goals-add-default-color-picker div {
  width: 100%;
  border-radius: 0 5px 5px 0;
}

#add-new-goal-editor {
  background: var(--extra-light-background-color);
  color: #fff;
  height: 150px;
  margin: 8px 0;
  border-radius: 5px;
}

#add-new-goal-editor .ql-editor.ql-blank::before {
  color: #a0a0a0;
}

.goals-add-exit-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.goals-add-exit-icon img {
  width: 28px;
  height: 28px;
}

.goals-add-new-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 35px;
  bottom: 35px;
  width: 55px;
  height: 55px;
  opacity: 1;
  background: #111;
  border-radius: 100%;
  border: solid 2px var(--main-color);
  cursor: pointer;
  transition: opacity 0.2s;
}

.goals-add-new-icon:hover {
  background: var(--extra-light-background-color);
}

.goals-add-new-icon img {
  height: 26px;
  width: 26px;
}

.goals-items-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  height: calc(100% - 50px);
}

.goals-no-items-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 2.5rem;
  color: #666;
  padding-bottom: 60px;
}

.goals-item-completed *:not(.goals-item-basic-title) {
  text-decoration: line-through;
}

.goals-item-completed-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.goals-item {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 110px;
  width: 560px;
  margin: 20px;
  transition: margin 0.5s;
}

.goals-item .ql-editor.ql-blank::before {
  color: #bbb;
}

.goals-item-color {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  left: 0;
  height: 110px;
  width: 110px;
  border-radius: 15px;
  z-index: 2;
}

.goals-item-color div {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--lighter-background-color);
  width: 35px;
  height: 35px;
  border-radius: 10px;
  margin: 6px;
}

.goals-item-color div img {
  padding: 4px;
}

.goals-item-main {
  position: relative;
  left: 45px;
  width: 460px;
  height: 100px;
  background: var(--lighter-background-color);
  padding-left: 45px;
}

.goals-item-select-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
}

.goals-item-select-wrapper > div {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #eee;
  cursor: pointer;
}

.goals-item-select-wrapper > div:hover {
  background: #eee;
}

.goals-item-mode-wrapper {
  position: absolute;
  display: flex;
  width: calc(100% - 85px);
  height: 100%;
}

.goals-item-basic-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin-left: 5px;
}

.goals-item-basic-is-editing {
  display: flex;
  margin: 5px 0;
}

.goals-item-basic-is-editing input {
  border: none;
  background: var(--extra-light-background-color);
  border-radius: 5px;
  padding-left: 4px;
  height: 24px;
}

.goals-item-basic-is-editing-img {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: var(--extra-light-background-color);
  border-radius: 5px;
  width: 24px;
  height: 24px;
  margin-left: 3px;
  cursor: pointer;
}

.goals-item-basic-is-editing-img:hover {
  background: #555;
}

.goals-item-basic-is-editing-img img {
  height: 14px;
  width: 14px;
}

.goals-item-basic-title {
  font-size: 1.7rem;
}

.goals-item-basic-desc {
  font-size: 1.1rem;
}

.goals-item-completion-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 4;
}

.goals-item-completion-deadline {
  display: flex;
}

.goals-item-completion-flatpickr-wrapper,
.goals-item-completion-auto-wrapper {
  position: relative;
  cursor: pointer;
}

.flatpickr-input {
  position: relative;
  display: flex;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  border-radius: 5px;
  color: #eee;
  background: var(--extra-light-background-color);
  height: 35px;
  width: 200px;
  opacity: 1;
  transition: opacity 0s;
  transition-delay: 0.05s;
}

.goals-item-completion-auto {
  display: flex;
}

.goals-item-completion-display-auto-completion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  border: none;
  border-radius: 5px;
  color: #eee;
  background: var(--extra-light-background-color);
  height: 35px;
  width: 200px;
  opacity: 1;
  transition: opacity 0s;
  transition-delay: 0.05s;
}

.goals-item-completion-display-auto-completion p {
  color: #979797;
  font-size: 15px;
}

.goals-item-completion-display-auto-completion > div:nth-child(1) {
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.goals-item-completion-display-auto-completion > div:nth-child(2) {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 28px;
  background: var(--lighter-background-color);
  width: 45%;
  border-radius: 5px;
}

.goals-item-completion-display-auto-window {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  right: -100px;
  top: 20px;
  width: 300px;
  background: #3f4458;
  height: 0;
  opacity: 0;
  z-index: 1;
  overflow: hidden;
  transition: opacity 0.2s, top 0.2s;
}

.goals-item-completion-display-auto-window > div select {
  cursor: pointer;
}

.goals-item-completion-display-auto-window > div select,
.goals-item-completion-display-auto-window > div input {
  padding: 0 5px;
  height: 40px;
  width: 200px;
  background: var(--extra-light-background-color);
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 1.1rem;
}

.goals-item-completion-title {
  display: flex;
  align-items: center;
  border-radius: 5px;
  width: 150px;
  height: 35px;
  background: var(--extra-light-background-color);
  margin: 0 10px;
  padding: 0 10px;
}

.goals-item-completion-change-date {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 5px;
  background: #555;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s;
}

.goals-item-completion-flatpickr-wrapper:hover
  .goals-item-completion-change-date,
.goals-item-completion-auto-wrapper:hover .goals-item-completion-change-date {
  opacity: 1;
}

.goals-item-completion-flatpickr-wrapper:hover .flatpickr-input {
  opacity: 0;
  z-index: 10;
}

.goals-item-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0;
  width: calc(100% - 40px);
}

.goals-item-content-tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-height: 54px;
  overflow: auto;
}

.goals-item-content-tags-wrapper > div,
.goals-item-content-color-wrapper > div {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: var(--extra-light-background-color);
  padding: 0 5px;
  margin: 0 5px 5px 5px;
  height: 24px;
}

.goals-item-content-tags-wrapper > img {
  border: 0;
  background: var(--extra-light-background-color);
  padding: 5px 5px;
  height: 24px;
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
}

.goals-item-content-tags-wrapper > img:hover {
  background: #555;
}

.goals-item-content-tag {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.goals-item-content-tag-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: var(--extra-light-background-color);
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  border-radius: 5px;
}

.goals-item-content-tag-delete:hover {
  opacity: 1;
  background: #444;
}

.goals-item-content-tag-delete img {
  width: 16px;
  height: 16px;
}

.goals-item-content-add-new-tag {
  display: flex;
  background: none !important;
}

.goals-item-content-add-new-tag button {
  width: 24px;
  height: 24px;
  margin-left: 5px;
  border: none;
  background: none;
}

.goals-item-content-add-new-tag button img {
  background: var(--extra-light-background-color);
  height: 24px;
  width: 24px;
  border: 0;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.goals-item-content-add-new-tag img:hover {
  background: #555;
}

.goals-item-content-add-new-tag input {
  background: var(--extra-light-background-color);
  height: 24px;
  width: 100px;
  border: 0;
  border-radius: 5px;
  padding-left: 4px;
}

.goals-item-content-color-wrapper {
  display: flex;
}

.goals-item-content-color-wrapper > div {
  margin: 0;
  margin-left: 5px;
}

.goals-item-content-color-selector {
  position: relative;
  margin: 0 !important;
}

.goals-item-content-color-selector input {
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  border-radius: 0;
}

.goals-item-content-color-wrapper div:nth-child(2):hover {
  background: #555;
}

.goals-item-content-extension {
  align-self: center;
  position: absolute;
  top: 100px;
  left: 0;
  height: 0px;
  width: calc(100% + 40px);
  border-radius: 0 0 15px 15px;
  background: var(--contrast-lighter-background-color);
  transition: 0.5s;
  overflow-x: visible;
  overflow-y: hidden;
}

.goals-item-buttons-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
  width: 0px;
  height: 100px;
  align-self: center;
  right: 5px;
  z-index: 0;
}

.goals-item-buttons-wrapper > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 50px;
  position: relative;
  cursor: pointer;
  background: var(--lighter-background-color);
  border-left: solid 3px #444;
  transition: background 0.2s;
}

.goals-item-buttons-wrapper > div:hover {
  background: #444;
}

.goals-item-buttons-wrapper > div img {
  width: 15px;
}

.goals-item-buttons-wrapper > div:nth-child(1) {
  border-radius: 0 10px 0 0;
}

.goals-item-buttons-wrapper > div:nth-child(2) {
  border-radius: 0 0 10px 0;
}
