.auth-background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: var(--main-color);
}

.auth-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--background-color);
  border-radius: 18px;
  padding: 55px;
  width: 80vw;
  max-width: 850px;
}

.auth-new-password-wrapper input {
  align-self: center;
  border: none;
  background: #efefef;
  color: #000;
  width: 100%;
  padding: 15px;
  margin: 8px 0;
  border-radius: 0 5px 5px 0;
  border-left: solid 8px var(--main-color);
}

.auth-input-wrapper {
  margin: 15px 0;
  width: 100%;
}

.auth-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 90%;
  max-width: 730px;
  margin: 30px 0;
}

.auth-input-text-wrapper {
  margin: 12px 0;
  width: 100%;
}

.auth-input-text {
  align-self: center;
  border: none;
  background: #efefef;
  color: #000;
  width: 100%;
  padding: 15px;
  border-radius: 0 5px 5px 0;
  border-left: solid 8px var(--main-color);
}

.auth-input-checkbox-wrapper {
  margin: 10px 0;
}

.auth-input-checkbox {
  display: flex;
  flex-direction: row;
}

.auth-input-checkbox input {
  width: initial;
}

.auth-forgot-password {
  text-align: right;
}

.auth-submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border: none;
  background: #efefef;
  color: #444;
  border-radius: 5px;
  padding: 12px;
  width: 60%;
  margin: 15px 0 0 0;
  cursor: pointer;
}

.auth-submit-btn:hover {
  background: #bfbfbf;
}

.auth-account-link-wrapper {
  display: flex;
  flex-direction: row;
  width: 90%;
}

.auth-below-login-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 25px;
}

.auth-below-login-wrapper * {
  margin: 0;
}

.auth-account-link {
  margin-left: 5px;
  color: #fff;
  text-decoration: underline;
}

.auth-signup-legal {
  position: relative;
  right: 10px;
  display: flex;
  padding-left: 14px;
}

.auth-signup-legal p {
  margin-right: 7px;
}

.auth-signup-legal p > a {
  color: var(--main-color);
  text-decoration: underline;
  margin: 0 4.5px;
}

.auth-title {
  font-size: 1.5rem;
  text-align: center;
}

.auth-text-box {
  margin-top: 10px;
  width: 100%;
  text-align: center;
}

.auth-error {
  display: flex;
  flex-direction: column;
  position: relative;
  color: red;
}

.auth-custom-error {
  display: inline;
  position: relative;
  color: red;
}

.auth-relative {
  position: relative;
}

.bold {
  font-weight: bold;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.space {
  margin-left: 5px;
}

/* Invite only signup */

.auth-invite-only-wrapper p {
  font-size: 1.1rem;
  margin: 28px 0;
}

.auth-discord-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background: var(--lighter-background-color);
}

.auth-discord-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background: var(--lighter-background-color);
}

.auth-discord-wrapper:hover {
  background: var(--extra-light-background-color);
}

.auth-discord-wrapper img {
  width: 50px;
  padding-right: 10px;
}

.auth-discord-wrapper p {
  font-size: 1.5rem;
}
