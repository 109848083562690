@media only screen and (max-width: 500px) {
  .auth-wrapper {
    justify-content: center;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    border-radius: 0;
  }

  .auth-title {
    font-size: 2.2rem;
    margin: 10px;
  }

  .auth-wrapper button {
    width: 100%;
  }

  .auth-form-wrapper {
    margin: 0;
  }

  .auth-account-link-wrapper {
    flex-direction: column;
    margin: 10px 0;
  }

  .auth-account-link-wrapper a {
    margin: 0;
    padding: 5px 0;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 501px) {
  .auth-wrapper {
    justify-content: center;
    width: 80vw;
    max-width: 100vw;
  }

  .auth-wrapper button {
    width: 100%;
  }

  .auth-form-wrapper {
    margin: 30px 0;
  }

  .auth-account-link-wrapper {
    flex-direction: column;
    margin: 10px 0;
  }

  .auth-account-link-wrapper a {
    margin: 0;
    padding: 5px 0;
  }
}
