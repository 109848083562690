.overview-wrapper {
  display: grid;
  justify-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.overview-inner-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr repeat(
      2,
      [col-start] minmax(135px, 1fr) [col-end]
    );
  grid-template-rows: minmax(250px, 3.6fr) minmax(400px, 6fr);
  grid-template-areas:
    "tl tl tl tr tr tr"
    "bl bl bl bl br br";
  justify-content: center;
  grid-gap: 20px;
  padding: 40px;
  width: 100%;
  max-width: 2000px;
  height: 100%;
  overflow-y: auto;
}

.overview-inner-wrapper > div {
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--background-color);
  border-radius: 10px;
}


.overview-none-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.overview-none-button-wrapper button {
  border: none;
  background: var(--main-color);
  font-size: 1.2rem;
  height: 50px;
  width: 250px;
  border-radius: 5px;
  cursor: pointer;
}

.overview-none-button-wrapper button:hover {
  background: var(--shade-main-color);
}
