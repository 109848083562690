.notes-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  padding: 40px;
}

.notes-large-panel-wrapper {
  position: relative;
  width: 65%;
  height: 100%;
  background: var(--lighter-background-color);
  margin-right: 20px;
}

.notes-panel-wrapper {
  height: calc(100% - 50px) !important;
  width: 100%;
}

.notes-list-background {
  display: flex;
  width: 100%;
  height: 100%;
  background: var(--background-color);
  border-radius: 0 0 10px 10px;
}

.notes-list-left {
  width: 100%;
  height: 100%;
  padding: 5px 15px;
  margin-bottom: 10px;
  overflow-y: auto;
}

.notes-max-amount-items-wrapper {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.notes-no-notes-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.notes-no-notes-wrapper h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 2.2rem;
  color: #666;
}

.notes-list-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 20px 10px;
  width: calc(100% - 20px);
  height: 70px;
  background: var(--contrast-lighter-background-color);
  padding: 0 0 0 10px;
}

.notes-list-item:hover {
  background: #252525;
}

.notes-list-item-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.notes-list-item-content h3 {
  position: relative;
  cursor: text;
  z-index: 10;
}

.notes-list-item-edit-title {
  position: relative;
  z-index: 10;
}

.notes-list-item-divider {
  border-bottom: solid 1.5px #666;
}

.notes-list-item:hover .notes-list-item-right-wrapper {
  display: flex;
}

.notes-list-item-hitbox {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.notes-list-item-right-wrapper {
  position: relative;
  display: none;
  flex-direction: row;
  align-items: center;
  position: absolute;
  padding-top: 6px;
  right: 15px;
  cursor: pointer;
  z-index: 10;
}

.notes-list-item-right-item img {
  width: 27px;
  height: 27px;
  margin-left: 10px;
}

.notes-list-item-share-settings img {
  width: 36px;
  height: 36px;
}

.notes-list-item-delete-icon {
  width: 24px !important;
  height: 24px !important;
}

.notes-list-right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65%;
  height: 100%;
  padding: 30px;
}
#preview-editor .ql-tooltip-editor {
  display: none;
}

#preview-editor .ql-editor {
  position: relative;
  overflow: hidden;
}

.notes-list-right-txt-background {
  background: #202020;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.notes-list-right-preview-txt {
  font-size: 5rem;
  color: #666;
}

.notes-list-item-share-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  left: 12px;
  width: 150px;
  height: 40px;
  background: #252525;
  border-radius: 8px 0 0 8px;
}

.notes-list-item-share-menu div img {
  width: 25px;
  height: 25px;
}

#notes-list-item-anonymous-icon img {
  width: 28px;
  height: 28px;
}

.notes-list-item-share-menu-link {
  background: none;
  border-radius: 6px;
  height: 35px;
  padding: 5px;
  transition: background 0.1s cubic-bezier(0, 0.99, 0.52, 0.32);
}

.notes-editor-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  border: solid 3px var(--background-color);
  border-top: none;
  border-radius: 0 0 10px 10px;
}

.notes-editor-save-background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: inherit;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 12;
}

.notes-editor-save-window {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 340px;
  height: 180px;
  background: var(--background-color);
  border: solid 2px var(--main-color);
  border-radius: 16px;
}

.notes-editor-save-window form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 340px;
  height: 180px;
}

.notes-editor-save-input {
  padding: 10px;
  background: #303030;
  margin: 10px;
  border: none;
  border-radius: 6px;
}

.notes-editor-save-submit {
  background: #303030;
  border: none;
  border-radius: 5px;
  padding: 5px 24px;
  cursor: pointer;
}

.notes-editor-save-cancel {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.notes-editor-save-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 35px;
  bottom: 30px;
  width: 55px;
  height: 55px;
  opacity: 1;
  background: #111;
  border-radius: 100%;
  border: solid 2px var(--main-color);
  cursor: pointer;
  transition: opacity 0.2s;
}

.notes-editor-save-btn:hover {
  background: #333;
}

.notes-editor-save-icon {
  height: 20px;
  width: 20px;
}

.notes-editor-add-icon {
  height: 26px;
  width: 26px;
}

.notes-editor-saving:hover {
  opacity: 1;
  cursor: default;
}

.ql-toolbar.ql-snow {
  display: block;
  width: 100%;
  border: 0;
  background: var(--background-color);
  min-height: 60px;
}

.ql-toolbar.ql-snow * *:hover {
  background: var(--extra-light-background-color);
  border-radius: 5px;
}

.ql-picker-options {
  background: var(--lighter-background-color) !important;
  border-radius: 5px !important;
  border: none !important;
}

.ql-toolbar .ql-stroke {
  fill: none;
  stroke: #fff;
}

.ql-toolbar .ql-fill {
  fill: #fff;
  stroke: none;
}

.ql-toolbar .ql-picker {
  color: #fff;
}

.ql-editing input {
  color: #000;
}

.note-editor-external-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  margin-top: 20px;
  overflow: auto;
}

#notes-editor {
  max-width: 600px;
  width: 100%;
  align-self: center;
  border: 0;
  background: var(--background-color);
}

.notes-tabs-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: var(--background-color);
  border-bottom: solid 2px var(--main-color);
  border-radius: 10px 10px 0 0;
  overflow-x: hidden;
}

.notes-divider,
.notes-large-panel-divider {
  width: 2px;
  height: 100%;
  background: var(--main-color);
}

.notes-tab {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  height: 100%;
  align-items: center;
  cursor: default;
  background: var(--background-color);
  cursor: pointer;
  transition: background 0.2s;
}

.notes-tab:hover {
  background: var(--contrast-lighter-background-color);
}

.notes-tab-text {
  margin-left: 8px;
}

.notes-tab-icon {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.notes-desktop-small-panel {
  width: 35%;
  height: 100%;
}

.notes-small-panel-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;
  height: 100%;
  min-width: 300px;
  max-width: 500px;
  background: var(--background-color);
  margin-left: 20px;
  border-radius: 10px;
}

.notes-small-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
  background: var(--lighter-background-color);
  margin: 10px 0;
  border: solid 2px var(--main-color);
  border-radius: 10px;
}

.notes-arrow-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 50px;
  height: 70px;
  transition: background 0.1s;
  z-index: 10;
}

.notes-arrow-wrapper:hover {
  background: rgba(10, 10, 10, 0.6);
}

.notes-arrow-wrapper-right {
  right: 0;
}

.notes-arrow-wrapper-left {
  left: 0;
}

.notes-arrow {
  width: 18px;
  margin: 15px;
  opacity: 0.7;
  transition: opacity 0.1s;
}

.notes-arrow-wrapper:hover .notes-arrow {
  opacity: 1;
}

.notes-arrow-right {
  transform: rotate(180deg);
}

.notes-box-content {
  width: 100%;
  height: 100%;
}

.notes-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.notes-small-link-preview-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--main-color);
}

.notes-small-link-preview-info {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  bottom: 0;
  height: 30%;
  width: 100%;
  color: var(--extra-light-background-color);
  background: rgba(255, 255, 255, 0.7);
  border-radius: 0 0 10px 10px;
  transition: background 0.2s;
  cursor: pointer;
}

.notes-small-link-preview-info:hover {
  background: rgba(255, 255, 255, 0.9);
}

.notes-small-content-image {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.notes-box-image {
  width: 100%;
  height: 100%;
}

.notes-no-content-msg {
  font-size: 1.5rem;
  color: #888;
}

.snotes-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding-top: 20px;
  overflow: hidden;
}

.snotes-bottom-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 12px;
  font-size: 1.1rem;
  height: 260px;
  width: 100%;
  max-width: 600px;
  background: var(--background-color);
  margin: 20px;
}

.snotes-editor {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100vw;
  overflow: auto;
}

.snotes-editor #editor {
  display: flex;
  justify-content: center;
  min-width: 640px;
  max-width: 640px;
}

.snotes-editor .ql-editor {
  width: 600px;
  background: var(--background-color);
}

.snotes-share-discord-wrapper {
  position: absolute;
  right: 15px;
  top: 15px;
}

.snotes-share-discord-wrapper a {
  display: flex;
  align-items: center;
}

.snotes-share-discord-wrapper a img {
  width: 40px;
  margin-right: 8px;
}
