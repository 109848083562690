:root {
  --main-color: #0086D6;
  --shade-main-color: #00619b;
  --background-color: #111116;
  --lighter-background-color: #222228;
  --contrast-lighter-background-color: #19191e;
  --extra-light-background-color: #333338;
  --red-color: #ef476f;
  --shade-red-color: #bf3858;
  --green-color: #06d6a0;
  --yellow-color: #ffd166;
}
