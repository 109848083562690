@media only screen and (max-height: 810px) {
  .goals-add-inner-wrapper {
    align-items: flex-start;
  }
}

@media only screen and (max-width: 970px) {
  .goals-wrapper {
    height: 100%;
    margin: 0;
    border-radius: 0;
  }
}

@media only screen and (max-width: 580px) {
  .goals-item {
    width: 480px;
    margin: 20px 0 20px 0;
  }
  .goals-item-color {
    width: 30px;
    left: 4px;
    height: 100px;
    border-radius: 10px 0 0 10px;
  }
  .goals-item-basic-wrapper {
    padding-left: 10px;
  }
  .goals-item-content-wrapper {
    padding: 10px 0 10px 10px;
    width: 100%;
  }
  .goals-item-content-extension {
    width: 430px;
  }
  .goals-item-main {
    left: 20px;
    width: 430px;
    padding: 0;
  }
}

@media only screen and (max-width: 500px) {
  .goals-items-wrapper {
    align-items: flex-start;
  }

  .goals-add-exit-wrapper {
    width: 100%;
  }

  .goals-add-default-wrapper {
    min-width: 0;
    width: 80%;
  }
}
