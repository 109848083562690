.settings-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

.settings-header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  flex: 0 0 100px;
  box-shadow: 0px 5px 5px 0px #0a0a0a;
  background: var(--background-color);
  z-index: 5;
}

.settings-header * {
  font-variant: small-caps;
  font-size: 4rem;
}

.settings-header-go-back {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  padding: 20px 20px 28px 20px;
  cursor: pointer;
  background: var(--main-color);
  transform: rotate(270deg);
}

.settings-prop-header * {
  height: 100px;
}

.settings-header-empty {
  height: 100px;
  width: 100px;
}

.settings-inner-wrapper {
  display: flex;
  background: var(--background-color);
  width: 80%;
  flex: 1;
}

.settings-inner-left {
  width: 100px;
  height: 100%;
  border-right: 3px solid #444;
  margin-right: 20px;
}

.settings-inner-left-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90px;
  cursor: pointer;
  border-bottom: 3px solid #444;
}

.settings-inner-left-item:hover {
  background: #2a2a2a;
}

.settings-inner-left-item img {
  width: 45px;
  height: 45px;
}

.settings-inner-right {
  width: 100%;
  height: 100%;
}

.settings-account-wrapper,
.settings-all-games-inner-wrapper {
  width: 100%;
  height: 100%;
  height: calc(100vh - 160px);
  overflow-y: auto;
}

.settings-account-wrapper {
  height: calc(100vh - 190px);
}

.settings-all-games-wrapper {
  height: 0;
}

.settings-text-header {
  padding-top: 40px;
  padding-bottom: 20px;
}

.settings-game-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 30px;
}

.settings-game-disclosure {
  color: #888;
  font-size: 0.75rem;
  max-width: 550px;
  margin-top: 5px;
}

.settings-game-field {
  width: 50% !important;
  padding-left: 7px;
}

.settings-game-select {
  align-self: center;
  text-align: center;
  font-size: 0.95rem;
  height: 45px;
  max-width: 50px;
  min-width: 50px;
  background: var(--lighter-background-color);
  border: none;
  border-left: solid 2px #383838;
  border-bottom: solid 2px #383838;
  border-top: solid 2px #383838;
  cursor: pointer;

  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.settings-game-select::-ms-expand {
  display: none;
}

.settings-game-select:focus {
  outline: none;
  border-left: solid 2px var(--main-color);
  border-bottom: solid 2px var(--main-color);
  border-top: solid 2px var(--main-color);
}

.settings-game-label {
  padding: 8px 12px 8px 0px;
  font-size: 1.3rem;
}

.settings-field-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.settings-user-field-wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.settings-field-wrapper input {
  width: 100%;
  height: 45px;
  padding-right: 10px;
  font-size: 1.1rem;
  color: #fff;
  background: var(--lighter-background-color);
  border-radius: 5px;
  padding-left: 6px;
  margin: 10px 0 10px 0;
  border: none;
  border-bottom: solid 2px #383838;
  border-top: solid 2px #383838;
  border-left: solid 2px #383838;
  border-radius: 5px 0px 0px 5px;
  transition: border ease 0.1s;
}

.settings-field-wrapper input:focus {
  border: solid 2px var(--main-color);
}

.settings-field-btn {
  display: flex;
  margin: 10px 0 10px 0;
  justify-content: center;
  align-items: center;
  min-width: 45px;
  max-width: 45px;
  height: 45px;
  background: var(--main-color);
  border: none;
  border-radius: 0px 5px 5px 0px;
  transition: background ease 0.2s;
  cursor: pointer;
}

.settings-field-btn img {
  width: 20px;
  height: 20px;
}

.settings-current-user-wrapper {
  display: flex;
  flex-direction: row;
}

.settings-steam-logout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  height: 35px;
  margin: 10px 0;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 4px 0px #808080;
  background: #272727;
}

.settings-steam-logout-small-txt {
  align-self: center;
  margin-top: 2px;
  text-shadow: 0 0 8px #111;
  font-family: Verdana, Geneva, sans-serif;
  font-size: 7.9px;
  font-weight: 600;
}

.settings-steam-logout-upper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Verdana, Geneva, sans-serif;
  font-weight: 600;
  width: 100%;
  font-size: 16px;
  border-radius: 10px 10px 0 0;
  height: 22px;
  background: linear-gradient(180deg, #7f7f7f, #5b5b5b);
  text-shadow: 0 0 8px #111;
}

.settings-steam-log-in {
  background: none;
  border: none;
  margin: 10px 0;
}

.settings-steam-log-in img {
  box-shadow: 0px 0px 4px 0px #808080;
  border-radius: 10px;
  cursor: pointer;
}

.settings-main-field-wrapper {
  display: flex;
  flex-direction: column;
}

.settings-field-key {
  padding: 8px 0;
}

.settings-field-value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px 0px 0px 5px;
  font-size: 1.1rem;
  padding: 0px 8px;
  height: 45px;
  width: 50%;
  background: var(--lighter-background-color);
}

.settings-field-value-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.settings-save-button {
  background: var(--main-color);
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  height: 30px;
  width: 80px;
  cursor: pointer;
}

.settings-logout-button {
  margin-top: 26px;
}

.settings-logout-button button {
  border: none;
  background: var(--main-color);
  padding: 10px 36px;
  border-radius: 8px;
  cursor: pointer;
}

.settings-logout-button button:hover {
  background: var(--shade-main-color);
}

.error {
  color: var(--red-color);
}

.profile-settings-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(100vh - 200px);
  width: 95%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 100px;
}

.profile-settings-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 75px;
  background: var(--lighter-background-color);
  border-radius: 5px;
  margin: 15px;
  width: 95%;
}

.profile-settings-item-header {
  padding-left: 15px;
  width: 50%;
}

.profile-settings-item-select {
  background: none;
  border: none;
  font-size: 1.1rem;
  width: 50%;

  background: linear-gradient(45deg, transparent 50%, #eee 50%),
    linear-gradient(135deg, #eee 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc), var(--main-color);
  background-position: calc(100% - 20px) calc(0.85em + 0px),
    calc(100% - 15px) calc(0.85em + 0px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1em, 1px calc(100% - 2.5em);
  background-repeat: no-repeat;

  /*border: 1px #eee solid;*/
  border-radius: 5px;
  line-height: 1.1em;
  padding: 0.4em 3.5em 0.4em 0.8em;
  margin-right: 15px;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  cursor: pointer;
}
