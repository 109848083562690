@media only screen and (max-width: 1016px) and (min-width: 970px) {
  .main-header-title {
    display: none;
  }
}

@media only screen and (max-width: 970px) {
  * {
    font-family: "Exo 2", sans-serif;
    color: white;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  .main-body-wrapper {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }

  .main-body-inner-wrapper {
    grid-template-columns: 0 100%;
    grid-template-rows: 67px 1fr;
    overflow-x: auto;
    min-height: 100%;
  }

  #root {
    min-width: 300px;
  }

  .main-top {
    width: 100vw;
  }

  .main-menu-icon {
    display: flex;
  }

  /*Header*/

  .main-header-wrapper {
    position: fixed;
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    width: 100vw;
    border-bottom: solid 2px var(--main-color);
    z-index: 100;
  }

  .main-header-mobile-upper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 65px;
    height: 100%;
    width: 100%;
  }

  .main-header-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
  }

  .main-header-title {
    font-size: 2.5rem;
    font-weight: 300;
    margin-left: 20px;
  }

  .main-header-right {
    width: 100%;
  }

  .main-header-mobile-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    width: 90px;
  }

  .main-header-update-mobile {
    display: inline;
  }

  .main-header-update-mobile .main-header-update-spinner img {
    width: 27px;
    height: 27px;
  }

  .main-header-timer-wrapper {
    padding: 0;
  }

  .main-header-timer-bar {
    display: none;
  }

  .main-header-update-desktop {
    display: none;
  }

  .sidebar-wrapper {
    height: calc(var(--vh, 1vh) * 100);
  }

  .main-header-show-sidebar {
    display: flex;
  }

  .main-menu-icon {
    width: 25px;
    height: 25px;
    margin-right: 15px;
    cursor: pointer;
  }

  .main-header-settings {
    display: none;
  }

  .main-header-select-wrapper {
    position: relative;
    flex-direction: row;
    justify-content: safe center;
    padding: 10px 0;
    margin: 0 5px;
    border-top: 2px solid #303030;
    width: 100%;
  }

  /* Main */

  .main-wrapper {
    margin: 0;
    justify-content: center;
    width: 100vw;
    padding-bottom: 50px;
  }

  /* Alert */
  .main-alert {
    justify-content: center;
    position: absolute;
    height: 140px;
    z-index: 99999;
  }

  .main-alert-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-alert-text-desktop {
    display: none;
  }

  .main-alert-text-mobile {
    display: block;
  }

  .main-alert-text-end {
    display: none;
  }

  .main-alert img {
    position: absolute;
    right: 12px;
    top: 12px;
  }

  .main-alert-sign {
    margin-top: 10px;
    padding: 8px 12px;
    background: #fff;
    color: var(--red-color);
    border-radius: 5px;
    cursor: pointer;
  }

  .main-alert-sign:hover {
    background: #fff;
    color: var(--red-color);
  }

  .overview-wrapper {
    display: grid;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .overview-inner-wrapper {
    padding: 20px 40px;
    grid-template-columns: 92vw;
    grid-template-rows: 300px 300px 300px 300px;
    grid-template-areas: "tl" "tr" "bl" "br";
    overflow: initial;
  }

  .stats-wrapper {
    display: grid;
  }

  .stats-inner-wrapper {
    padding: 0 0 20px 0;
    width: calc(var(--vw, 1vw) * 100);
  }
}

@media only screen and (max-width: 1200px) and (min-width: 870px) {
  .main-main-wrapper {
    height: 100%;
    overflow-y: hidden;
    margin: 0;
  }
}

@media only screen and (max-width: 330px) {
  .main-header-title {
    display: none;
  }

  .main-header-mobile-upper {
    justify-content: flex-end;
  }
}
